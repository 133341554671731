import { OnInit } from '@angular/core';
var SidenavComponent = /** @class */ (function () {
    function SidenavComponent() {
        this.sideItems = [
            {
                label: "Dashboard",
                link: "/home",
                icon: "fa fa-home fa-3x",
                scale: 'in'
            },
            {
                label: "History",
                link: "/history",
                icon: "fa fa-history fa-3x",
                scale: "in"
            },
        ];
    }
    SidenavComponent.prototype.ngOnInit = function () {
    };
    SidenavComponent.prototype.ToggleSelectedLink = function (i) {
        if (this.sideItems[i].scale !== 'out') {
            this.sideItems[i].scale = this.sideItems[i].scale === 'out' ? 'in' : 'out';
        }
        for (var x in this.sideItems) {
            if (Number(x) !== i) {
                this.sideItems[Number(x)].scale = 'in';
            }
        }
    };
    return SidenavComponent;
}());
export { SidenavComponent };
