import { Injectable } from "@angular/core";
import { HttpClient ,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Observable, BehaviorSubject} from 'rxjs/Rx';
import { Subscription } from "rxjs/Subscription";
import { HttpHeaderResponse } from "@angular/common/http/src/response";
import { environment } from '../environments/environment'; 
import { UserLocationDetails } from './models/UserLocationDetails'
import { UserDetails } from './models/UserDetails';
import { Toast } from 'angular2-toaster';
import { ToasterService } from "angular2-toaster";

@Injectable()
export class AppService {
  
 
  querystring = "";
  private _userName = new BehaviorSubject<string>('');
  userName = this._userName.asObservable();
  private _toast = new BehaviorSubject<Toast[]>([]);
  toast = this._toast.asObservable();
  

  constructor( private http: HttpClient, private router:Router, private activatedRoute: ActivatedRoute, private toasterService:ToasterService) {

    this.activatedRoute.queryParams.subscribe((
      params: Params) => {
          if(params["userName"] && params["timeStamp"] && params["seed"]){
              this.querystring = `?userName=${params["userName"]}&timeStamp=${params["timeStamp"]}&seed=${encodeURIComponent(params["seed"])}`;
              this.http.get(environment.tokenIssuerPath +'/api/jwt/getjwt'+this.querystring).subscribe(
                  token  =>{
                    localStorage.setItem('token', token["returnJwt"]);
                    /* setTimeout(() => {
                      this.toasterService.pop("error", "Your Token Has Expired");
                      setTimeout(() => {
                        localStorage.removeItem('token');
                        window.location.href= environment.tokenIssuerClient;
                      }, 3600000 * 8);
                    },(3600000 * 8) -2000 ); */
                    this.router.navigate(['/home'])  
                    this.SetUser(params["userName"]);
                    },
                        (err: HttpErrorResponse) => {
                          if (err.error instanceof Error) {
                            console.log("Client-side error occured.", err.error);
                          } else {
                            console.log("Server-side error occured.", err.error);
                          }
                    });
            }  
});
        this.GetUserLocations();
        this.GetUserDetails();
     
}
  SetUser(newUser){
      this._userName.next(newUser);
  }
 AddToast(toast){
  this.changeToast(toast);
 }
 changeToast(toast:Toast[]) {
  this._toast.next(toast);
}

  GetUserLocations() {
     return this.http.get<UserLocationDetails[]>(environment.serverPath +'api/qview/userlocs')
  } 

 GetUserDetails() {
  return this.http.get<any>(environment.serverPath +'api/qview/users');
} 
  //TO DO ADD PARAMS AND MAKE CONFIGURABLE
  CreateMap(){
    return {
      center: new google.maps.LatLng(37.0902, -95.7129),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      styles:[],
      gestureHandling:null
    };
  }
  CreateIcon(url, scaledSize, anchor, origin){
    return {
        url: url, 
        scaledSize: scaledSize, 
        origin:origin,
        anchor: anchor,
    }
  }
  CreateMarker(position:any, map:google.maps.Map, icon:any, title:string, zIndex?:any):google.maps.Marker{
    return new google.maps.Marker({
      position: position,
      map: map,
      icon: icon, 
      title:title,
      zIndex:zIndex,
      optimized: false
    });
  }
}


