import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
var routes = [
    { path: 'home', component: HomeComponent },
    {
        path: 'history',
        loadChildren: './history/history.module#HistoryModule',
    },
    {
        path: 'messages',
        loadChildren: './message/message.module#MessageModule',
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
