<div #gmap class="homemap"></div>

<div *ngIf="showDetails" [@showUserDetails] [@rotatedState]='state' class="d-flex justify-content-center userinfo">
    <div class="d-flex flex-column w-100 h-100">
        <div class="d-flex flex-row topsection">
            <div class="mr-auto">
                <i class="fa fa-arrow-circle-left fa-2x d-flex justify-content-start pt-1 ml-2" (click)="rotate()" aria-hidden="true"></i>
            </div>
            <div>
                <i class="fa fa-window-close fa-2x d-flex justify-content-end mt-1 mr-2 closeicon" (click)="CloseUserDetails()" aria-hidden="true"></i>
            </div>
        </div>
        <div *ngIf="state ==='default'" class="mt-4">
            <div class="d-flex justify-content-center mb-3">
                <img *ngIf="userInfo?.image" class="img-fluid img-rounded profileimage" src="data:image/png;base64,{{userInfo?.image}}" />
                <img *ngIf="!userInfo?.image" class="img-fluid img-rounded profileimage" src="../../assets/images/defaultprofile.png" />
            </div>
            <div class="d-flex justify-content-center">
                <h5 style="color: white">{{userInfo?.firstName}} {{userInfo?.lastName}}</h5>
            </div>
            <hr>
            <div class="d-flex-row p-2 ml-4 mr-4 mb-2 userdetails">
                <strong><p  class="d-flex justify-content-center">Phone: </p></strong>
                <hr style="margin-top:-10px;">
                <p class="d-flex justify-content-center"> {{userInfo?.phoneNumber}}</p>
            </div>

            <div class="d-flex-row p-2 ml-4  mb-2 userdetails">
                <strong><p  class="d-flex justify-content-center">Email: </p></strong>
                <hr style="margin-top:-10px;">
                <p class="d-flex justify-content-center">
                    <a style="color:white" [href]="'mailto:' +  userInfo?.email">{{userInfo?.email}}</a>
                </p>
            </div>

            <div class="d-flex-row p-2 ml-4  userdetails">
                <strong><p  class="d-flex justify-content-center">Team: </p></strong>
                <hr style="margin-top:-10px;">
                <p class="d-flex justify-content-center"> {{userInfo?.team}}</p>
            </div>
        </div>
        <div [@rotatedState]='state' *ngIf="state ==='rotated'" class="mt-4 ">
            <div>
                <div class="d-flex flex-column w-100 h-100 p-2">

                    <div class="d-flex flex-row d-flex justify-content-left ml-4">
                        <h5>{{company.name}}<br>{{company.address}}<br>{{company.ctystzip}}</h5>
                    </div>
                    <hr>
                    <div *ngFor="let position of company.position" class="d-flex flex-row d-flex justify-content-left ml-4 mb-3">
                        <h5>{{position.title}}: {{position.name}}<br>{{position.phoneNumber}}</h5>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center flex-column" style="height: 350px;">
                <div class="flex-row d-flex justify-content-center mt-auto">Visit us at&nbsp;
                    <a target="_blank" href="http://www.ques.com">ques.com</a>
                </div>
            </div>
        </div>
    </div>
</div>
<input #searchbox class="controls k-textbox search" type="text" placeholder="Search a Location..." kendoTextBox />
<img class="img-fluid mapqlogo" src="../../assets/images/qview.png" />


<kendo-dialog *ngIf="noUsersLoggedIn" (close)="noUsersLoggedIn = false" [minWidth]="250" [width]="450">
    <kendo-dialog-titlebar>
        <div style="text-align: center; color:red; height:20px;">
            <i class="fa fa-exclamation-triangle" aria-hidden="true">Warning</i>

        </div>
    </kendo-dialog-titlebar>
    <h5 style="text-align: center">No Users are Currently Logged In</h5>
    <kendo-dialog-actions>
        <button style="background: grey" (click)="noUsersLoggedIn = false">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>