var UserLocationDetails = /** @class */ (function () {
    function UserLocationDetails(userName, locationDTM, latitude, longitude) {
        this.userName = userName;
        this.locationDTM = locationDTM;
        this.latitude = latitude;
        this.longitude = longitude;
    }
    return UserLocationDetails;
}());
export { UserLocationDetails };
