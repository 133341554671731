import { HomeService } from '../home/home.service';
/// <reference types="googlemaps" /> 

import { UserLocationDetails } from '../models/UserLocationDetails';
import { UserDetails } from '../models/UserDetails';
import {Component, ElementRef, ViewChild, NgZone} from '@angular/core';
import { SHOW_USERDETAILS, FLIP_USERDETAILS } from '../animations/animations';
import { MAP_STYLE } from  './../mapstyle'
import { Observable } from "rxjs/Rx";
import { AppService } from '../app.service';

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations:[
    SHOW_USERDETAILS,
    FLIP_USERDETAILS
]
})
export class HomeComponent {  
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('searchbox') searchbox: any;
  map: google.maps.Map;
  markers:google.maps.Marker[] = [];
  userLocations:UserLocationDetails[] = [];
  previousLocations:UserLocationDetails[] = [];
  userDetails:UserDetails[] = [];
  showDetails:boolean = false;
  initialLoad:boolean = true;
  state: string = 'default';
  userInfo;
  noUsersLoggedIn:boolean = false;
  alive:boolean = true;
   company:any = {
    name:"QUES Corporate Office",
    address: "4770 N. Belleview Ave. Suite 100",
    ctystzip: "Kansas City, MO 641116",
    position:[
      {
        title: "President",
        name: "Alyx Galan",
        phoneNumber: "(805) 657-0787"
      },
      {
        title: "Vice President",
        name: "John Czaicki",
        phoneNumber: "(314) 608-6340"
      },
      {
        title: "Safety Director",
        name: "Nate Strickland",
        phoneNumber: "(573) 590-0809"
      }
    ]   
  }
  
  constructor(private homeService:HomeService, private zone:NgZone, private appService:AppService) {
  }
  
  ngOnInit() {
      this.GetUserDetails();
      this.CreateMap();
      this.GetUserLocations();  
  }
  
  rotate() {
      this.state = (this.state === 'default' ? 'rotated' : 'default');
  }
  CloseUserDetails(){
    this.showDetails = false;
    this.state = 'default';
  }
  
  CreateMap(){  
      let mapProp = this.appService.CreateMap();
      mapProp.styles = MAP_STYLE;
      mapProp.gestureHandling = 'greedy';
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      this.CreateSearchBox();
  }
  CreateSearchBox(){
    let autocomplete = new google.maps.places.Autocomplete(this.searchbox.nativeElement);
    autocomplete.bindTo('bounds', this.map);

    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace();
      
      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      
      this.zone.run(() => {
        if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
        } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(7);
        }
     })
        let address = '';
        if (place.address_components) {
          address = [
            (place.address_components[0] && place.address_components[0].short_name || ''),
            (place.address_components[1] && place.address_components[1].short_name || ''),
            (place.address_components[2] && place.address_components[2].short_name || '')
          ].join(' ');
        }
    })
}

AddMarker(location){  
  let primary_icon = this.appService.CreateIcon("../../assets/images/Photo pin blue.png", new google.maps.Size(60, 80), new google.maps.Point(30, 80), new google.maps.Point(0,0));
  let photo_icon = this.appService.CreateIcon(this.GetUserImage(location.userName), new google.maps.Size(55, 45), new google.maps.Point(27, 75), new google.maps.Point(0, 0));
  let primary_marker = this.appService.CreateMarker(location.coords, this.map, primary_icon, location.userName, 9999);
  this.markers.push(primary_marker);
  this.AddMarkerListener(primary_marker);
  let photo_marker = this.appService.CreateMarker(location.coords, this.map, photo_icon, location.userName);
  this.markers.push(photo_marker);
  this.AddMarkerListener(photo_marker);
}

AddMarkerListener(marker){
  
  marker.addListener('click', (e) => {
    let currentUserInfo = this.userLocations.filter(user => user.userName.toLowerCase() === marker.getTitle().toLowerCase())[0];

    this.userInfo = this.userDetails.filter(user => user.userName.toLowerCase() === currentUserInfo.userName.toLowerCase())[0];
    
    this.zone.run(() => {
      this.showDetails = true;   
  })})
}

GetUserImage(user){
    let image = this.userDetails.filter(userInfo => userInfo.userName.toLowerCase() === user.toLowerCase())[0].image;
    return image !==null ? "data:image/png;base64,"+ image : "../../assets/images/defaultprofile.png"
}
NoUserLocations(userLocations):boolean{
  return userLocations.length === 0 ? true : false;
}
GetUserLocations(){ 
  Observable.interval(1000).subscribe(x => {
    this.appService.GetUserLocations()
      .subscribe(locations => {
       this.previousLocations = this.userLocations;
       this.userLocations = [];
       
       if(this.NoUserLocations(locations) && this.initialLoad){
          this.noUsersLoggedIn = true;
       }
       
           locations.map( location =>
              {
              this.userLocations.push(new UserLocationDetails(
                location.userName,
                location.locationDTM,
                location.latitude,
                location.longitude
              ))
              
               if(this.initialLoad){
                this.AddMarker({coords:{lat:location.latitude, lng: location.longitude}, userName:location.userName}); 
               }
             
               else{
                this.markers.filter( user => {

                  if(user.getTitle() === location.userName){
                   let prevLoc = this.previousLocations.filter( pp => pp.userName === location.userName)[0];
                 
                   let deltalat = (location.latitude - prevLoc.latitude) / 100;
                   let deltalng = (location.longitude - prevLoc.longitude) / 100;
                   
                   for (var i = 1; i < 100; i++) {
                    ((ind) => {
                      setTimeout(
                        () => {
                          var lat = user.getPosition().lat();
                          var lng = user.getPosition().lng();
                          lat += deltalat;
                          lng += deltalng;
                          let latlng = new google.maps.LatLng(lat, lng);
                          user.setPosition(latlng);
                        }, 10 * ind
                      );
                    })(i)
                  }  
                    this.AddMarkerListener(user);
                  }
            });

          }
              }) 
          });
          if(this.initialLoad){
                  setTimeout(() =>{this.initialLoad = false; }, 1000);
          }
        });
}
GetUserDetails(){
  
  this.appService.GetUserDetails()
  .takeWhile(() => this.alive)
  .subscribe(users => {
    users.userDetails.map( user =>
      {
          this.userDetails.push(new UserDetails(
            user.userName,
            user.firstName,
            user.lastName,
            user.phoneNumber,
            user.image,
            user.email,
            user.team
          )) 
        }) 
  })
}
ngOnDestroy(): void {
  this.alive = false;
}

}
