import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../environments/environment';
import { ToasterService } from "angular2-toaster";
var AppService = /** @class */ (function () {
    function AppService(http, router, activatedRoute, toasterService) {
        var _this = this;
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.toasterService = toasterService;
        this.querystring = "";
        this._userName = new BehaviorSubject('');
        this.userName = this._userName.asObservable();
        this._toast = new BehaviorSubject([]);
        this.toast = this._toast.asObservable();
        this.activatedRoute.queryParams.subscribe(function (params) {
            if (params["userName"] && params["timeStamp"] && params["seed"]) {
                _this.querystring = "?userName=" + params["userName"] + "&timeStamp=" + params["timeStamp"] + "&seed=" + encodeURIComponent(params["seed"]);
                _this.http.get(environment.tokenIssuerPath + '/api/jwt/getjwt' + _this.querystring).subscribe(function (token) {
                    localStorage.setItem('token', token["returnJwt"]);
                    /* setTimeout(() => {
                      this.toasterService.pop("error", "Your Token Has Expired");
                      setTimeout(() => {
                        localStorage.removeItem('token');
                        window.location.href= environment.tokenIssuerClient;
                      }, 3600000 * 8);
                    },(3600000 * 8) -2000 ); */
                    _this.router.navigate(['/home']);
                    _this.SetUser(params["userName"]);
                }, function (err) {
                    if (err.error instanceof Error) {
                        console.log("Client-side error occured.", err.error);
                    }
                    else {
                        console.log("Server-side error occured.", err.error);
                    }
                });
            }
        });
        this.GetUserLocations();
        this.GetUserDetails();
    }
    AppService.prototype.SetUser = function (newUser) {
        this._userName.next(newUser);
    };
    AppService.prototype.AddToast = function (toast) {
        this.changeToast(toast);
    };
    AppService.prototype.changeToast = function (toast) {
        this._toast.next(toast);
    };
    AppService.prototype.GetUserLocations = function () {
        return this.http.get(environment.serverPath + 'api/qview/userlocs');
    };
    AppService.prototype.GetUserDetails = function () {
        return this.http.get(environment.serverPath + 'api/qview/users');
    };
    //TO DO ADD PARAMS AND MAKE CONFIGURABLE
    AppService.prototype.CreateMap = function () {
        return {
            center: new google.maps.LatLng(37.0902, -95.7129),
            zoom: 5,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            styles: [],
            gestureHandling: null
        };
    };
    AppService.prototype.CreateIcon = function (url, scaledSize, anchor, origin) {
        return {
            url: url,
            scaledSize: scaledSize,
            origin: origin,
            anchor: anchor,
        };
    };
    AppService.prototype.CreateMarker = function (position, map, icon, title, zIndex) {
        return new google.maps.Marker({
            position: position,
            map: map,
            icon: icon,
            title: title,
            zIndex: zIndex,
            optimized: false
        });
    };
    return AppService;
}());
export { AppService };
