import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [ 
  { path: 'home' , component:HomeComponent },
  {
    path: 'history',
    loadChildren: './history/history.module#HistoryModule',
  },
  {
    path: 'messages',
    loadChildren: './message/message.module#MessageModule',
  }
];

  

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
