export class UserDetails {

    userName:string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    image:string;
    email:string;
    team:string;
    
    constructor(userName:string, firstName:string, lastName:string, phoneNumber:string, image:string, email:string, team:string){
        this.userName = userName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.image = image;
        this.email = email;
        this.team = team;
    }
}