import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from '../home/home.component';
import { HomeService } from '../home/home.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { RouterModule } from '@angular/router';
import { DialogModule }  from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    InputsModule,
    DialogModule,
    ButtonsModule
  ],
  providers:[HomeService],
  declarations: [HomeComponent],
  exports: [HomeComponent]
})
export class HomeModule { }
