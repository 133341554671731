import { HomeService } from '../home/home.service';
/// <reference types="googlemaps" /> 
import { UserLocationDetails } from '../models/UserLocationDetails';
import { UserDetails } from '../models/UserDetails';
import { NgZone } from '@angular/core';
import { MAP_STYLE } from './../mapstyle';
import { Observable } from "rxjs/Rx";
import { AppService } from '../app.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(homeService, zone, appService) {
        this.homeService = homeService;
        this.zone = zone;
        this.appService = appService;
        this.markers = [];
        this.userLocations = [];
        this.previousLocations = [];
        this.userDetails = [];
        this.showDetails = false;
        this.initialLoad = true;
        this.state = 'default';
        this.noUsersLoggedIn = false;
        this.alive = true;
        this.company = {
            name: "QUES Corporate Office",
            address: "4770 N. Belleview Ave. Suite 100",
            ctystzip: "Kansas City, MO 641116",
            position: [
                {
                    title: "President",
                    name: "Alyx Galan",
                    phoneNumber: "(805) 657-0787"
                },
                {
                    title: "Vice President",
                    name: "John Czaicki",
                    phoneNumber: "(314) 608-6340"
                },
                {
                    title: "Safety Director",
                    name: "Nate Strickland",
                    phoneNumber: "(573) 590-0809"
                }
            ]
        };
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.GetUserDetails();
        this.CreateMap();
        this.GetUserLocations();
    };
    HomeComponent.prototype.rotate = function () {
        this.state = (this.state === 'default' ? 'rotated' : 'default');
    };
    HomeComponent.prototype.CloseUserDetails = function () {
        this.showDetails = false;
        this.state = 'default';
    };
    HomeComponent.prototype.CreateMap = function () {
        var mapProp = this.appService.CreateMap();
        mapProp.styles = MAP_STYLE;
        mapProp.gestureHandling = 'greedy';
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.CreateSearchBox();
    };
    HomeComponent.prototype.CreateSearchBox = function () {
        var _this = this;
        var autocomplete = new google.maps.places.Autocomplete(this.searchbox.nativeElement);
        autocomplete.bindTo('bounds', this.map);
        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            _this.zone.run(function () {
                if (place.geometry.viewport) {
                    _this.map.fitBounds(place.geometry.viewport);
                }
                else {
                    _this.map.setCenter(place.geometry.location);
                    _this.map.setZoom(7);
                }
            });
            var address = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
            }
        });
    };
    HomeComponent.prototype.AddMarker = function (location) {
        var primary_icon = this.appService.CreateIcon("../../assets/images/Photo pin blue.png", new google.maps.Size(60, 80), new google.maps.Point(30, 80), new google.maps.Point(0, 0));
        var photo_icon = this.appService.CreateIcon(this.GetUserImage(location.userName), new google.maps.Size(55, 45), new google.maps.Point(27, 75), new google.maps.Point(0, 0));
        var primary_marker = this.appService.CreateMarker(location.coords, this.map, primary_icon, location.userName, 9999);
        this.markers.push(primary_marker);
        this.AddMarkerListener(primary_marker);
        var photo_marker = this.appService.CreateMarker(location.coords, this.map, photo_icon, location.userName);
        this.markers.push(photo_marker);
        this.AddMarkerListener(photo_marker);
    };
    HomeComponent.prototype.AddMarkerListener = function (marker) {
        var _this = this;
        marker.addListener('click', function (e) {
            var currentUserInfo = _this.userLocations.filter(function (user) { return user.userName.toLowerCase() === marker.getTitle().toLowerCase(); })[0];
            _this.userInfo = _this.userDetails.filter(function (user) { return user.userName.toLowerCase() === currentUserInfo.userName.toLowerCase(); })[0];
            _this.zone.run(function () {
                _this.showDetails = true;
            });
        });
    };
    HomeComponent.prototype.GetUserImage = function (user) {
        var image = this.userDetails.filter(function (userInfo) { return userInfo.userName.toLowerCase() === user.toLowerCase(); })[0].image;
        return image !== null ? "data:image/png;base64," + image : "../../assets/images/defaultprofile.png";
    };
    HomeComponent.prototype.NoUserLocations = function (userLocations) {
        return userLocations.length === 0 ? true : false;
    };
    HomeComponent.prototype.GetUserLocations = function () {
        var _this = this;
        Observable.interval(1000).subscribe(function (x) {
            _this.appService.GetUserLocations()
                .subscribe(function (locations) {
                _this.previousLocations = _this.userLocations;
                _this.userLocations = [];
                if (_this.NoUserLocations(locations) && _this.initialLoad) {
                    _this.noUsersLoggedIn = true;
                }
                locations.map(function (location) {
                    _this.userLocations.push(new UserLocationDetails(location.userName, location.locationDTM, location.latitude, location.longitude));
                    if (_this.initialLoad) {
                        _this.AddMarker({ coords: { lat: location.latitude, lng: location.longitude }, userName: location.userName });
                    }
                    else {
                        _this.markers.filter(function (user) {
                            if (user.getTitle() === location.userName) {
                                var prevLoc = _this.previousLocations.filter(function (pp) { return pp.userName === location.userName; })[0];
                                var deltalat_1 = (location.latitude - prevLoc.latitude) / 100;
                                var deltalng_1 = (location.longitude - prevLoc.longitude) / 100;
                                for (var i = 1; i < 100; i++) {
                                    (function (ind) {
                                        setTimeout(function () {
                                            var lat = user.getPosition().lat();
                                            var lng = user.getPosition().lng();
                                            lat += deltalat_1;
                                            lng += deltalng_1;
                                            var latlng = new google.maps.LatLng(lat, lng);
                                            user.setPosition(latlng);
                                        }, 10 * ind);
                                    })(i);
                                }
                                _this.AddMarkerListener(user);
                            }
                        });
                    }
                });
            });
            if (_this.initialLoad) {
                setTimeout(function () { _this.initialLoad = false; }, 1000);
            }
        });
    };
    HomeComponent.prototype.GetUserDetails = function () {
        var _this = this;
        this.appService.GetUserDetails()
            .takeWhile(function () { return _this.alive; })
            .subscribe(function (users) {
            users.userDetails.map(function (user) {
                _this.userDetails.push(new UserDetails(user.userName, user.firstName, user.lastName, user.phoneNumber, user.image, user.email, user.team));
            });
        });
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        this.alive = false;
    };
    return HomeComponent;
}());
export { HomeComponent };
