<div class="container h-90 p-2 mt-2">
    <div class="row flex-column p-0 mb-2 align-items-center" [ngClass]="{'menu':item.scale==='out'}" [@hoverlink]='item.scale' *ngFor="let item of sideItems; let i = index;">
        <a [routerLink]="[item.link]">
            <div class="col-12 text-center p-0 pt-4" (click)="ToggleSelectedLink(i)">
                <div class="d-flex align-items-end justify-content-center mb-1">
                    <i class="{{item.icon}}" [routerLink]="[item.link]"></i>
                </div>
                <div class="col-12 d-flex justify-content-center p-0">
                    <div class="d-none d-lg-block mb-4">
                        {{item.label}}
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>