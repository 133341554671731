import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SidenavComponent } from './sidenav/sidenav.component'
import { TopnavComponent } from './topnav/topnav.component'
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule }  from '@progress/kendo-angular-dialog';
import { ToasterModule, ToasterService} from "angular2-toaster";

import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    ButtonsModule,
    CommonModule,
    RouterModule, 
    DialogModule,
    ToasterModule
  ],
  declarations: [DashboardComponent, SidenavComponent, TopnavComponent],
  providers:[ToasterService],
  exports: [DashboardComponent]
})
export class DashboardModule { }
