import { Component, OnInit, ElementRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { environment } from '../environments/environment';
import { AppService } from "./app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  userName:string;
  
  constructor(private appService: AppService, private elementRef: ElementRef){
    this.appService.userName.subscribe(user => this.userName = user);
    console.log("Environment:",environment.serverPath)  
}
ngAfterViewInit(){
  this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#C0C0C0';
}
}

