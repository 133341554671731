import { Component, OnInit } from '@angular/core';
import { LINK_HOVER_EFFECT } from '../../animations/animations';
@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations:[
    LINK_HOVER_EFFECT
  ]
})
export class SidenavComponent implements OnInit {

  constructor() { }

  userName:string;
  
  
  sideItems:any = [
    {
      label:"Dashboard",
      link:"/home",
      icon:"fa fa-home fa-3x",
      scale:'in'
    },
   
    {
      label:"History",
      link:"/history",
      icon:"fa fa-history fa-3x",
      scale:"in"
    },
    /* {
      label:"Messages",
      link:"/messages",
      icon:"fa fa-comments fa-3x",
      scale:"in"
    } */

  ]
  ngOnInit() {
  }
  ToggleSelectedLink(i:number) {
    if(this.sideItems[i].scale !== 'out'){
    this.sideItems[i].scale = this.sideItems[i].scale === 'out' ? 'in' : 'out';
    }
    
    for(let x in this.sideItems){
      if(Number(x) !== i){
        this.sideItems[Number(x)].scale ='in';
      }
    }
  }

}
