import { OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { ToasterService, Toast } from "angular2-toaster";
var TopnavComponent = /** @class */ (function () {
    function TopnavComponent(appService, toasterService) {
        var _this = this;
        this.appService = appService;
        this.toasterService = toasterService;
        this.mouseOvered = false;
        this.searchMode = false;
        this.openSupportDialog = false;
        this.toast = [];
        this.showtoasters = false;
        this.settings = [
            { text: 'Profile' },
            { text: 'Return to Portal' },
            { text: 'Support' },
            { text: 'Log Out' }
        ];
        this.toasterService = toasterService;
        this.appService.userName.subscribe(function (user) { return _this.userName = user; });
    }
    TopnavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appService.GetUserDetails().subscribe(function (users) {
            _this.userAvatar = _this.GetAvatar(users);
        });
        this.appService.toast.subscribe(function (toast) { return _this.toast = toast; });
    };
    TopnavComponent.prototype.GetAvatar = function (users) {
        var _this = this;
        var i = users.userDetails.filter(function (user) { return _this.userName === user.userName; });
        if (i.length === 0 && typeof (i.image) == 'undefined' || i.image === null) {
            return "../../assets/images/noavatar.jpg";
        }
        return "data:image/png;base64," + i[0].image;
    };
    TopnavComponent.prototype.onItemClick = function (value) {
        switch (value.text) {
            case "Profile":
                window.location.href = environment.tokenIssuerClient + ("?gtma=" + this.userName);
                break;
            case "Return to Portal":
                window.location.href = environment.tokenIssuerClient + ("?kmli=" + this.userName);
                break;
            case "Support":
                this.openSupportDialog = true;
                break;
            case "Log Out":
                window.location.href = environment.tokenIssuerClient;
                break;
        }
    };
    TopnavComponent.prototype.ShowToasters = function () {
        var _this = this;
        this.toast.map(function (toast) { return _this.toasterService.pop(toast); });
    };
    return TopnavComponent;
}());
export { TopnavComponent };
