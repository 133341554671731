import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { ToasterModule, ToasterService, BodyOutputType, Toast } from "angular2-toaster";
@Component({
  selector: 'topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  userName:string;
  userAvatar:string;
  mouseOvered:boolean = false;
  searchMode:boolean = false;
  openSupportDialog:boolean = false;
  toast:Toast[] = [];
  showtoasters:boolean = false;
  settings: Array<any> = [
    {text: 'Profile'}, 
    {text: 'Return to Portal'}, 
    {text: 'Support'}, 
    {text: 'Log Out'}
  ];
constructor(private appService:AppService, private toasterService:ToasterService) { 
  this.toasterService = toasterService;
  this.appService.userName.subscribe(user => this.userName = user);
}

  ngOnInit() {
    this.appService.GetUserDetails().subscribe( users => {

        this.userAvatar = this.GetAvatar(users)
    });
    this.appService.toast.subscribe(toast => this.toast = toast);

  }
  GetAvatar(users):string {
      let i = users.userDetails.filter(user => this.userName === user.userName);
     
      if(i.length === 0 && typeof(i.image) == 'undefined' || i.image === null){
        return "../../assets/images/noavatar.jpg";
     }
        return "data:image/png;base64,"+ i[0].image;    
  }
  onItemClick(value){
    switch(value.text){
      case "Profile":
          window.location.href= environment.tokenIssuerClient+`?gtma=${this.userName}`;
      break;

      case "Return to Portal":
        window.location.href= environment.tokenIssuerClient+`?kmli=${this.userName}`;
      break;

      case "Support":
          this.openSupportDialog = true;
      break;

      case "Log Out":
        window.location.href= environment.tokenIssuerClient
      break;
    }
  }
  ShowToasters(){
     this.toast.map(toast => this.toasterService.pop(toast));
  }

}
