import { Injectable, OnInit } from "@angular/core";
import { HttpClient ,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Observable, BehaviorSubject} from 'rxjs/Rx';
import { Subscription } from "rxjs/Subscription";
import { HttpHeaderResponse } from "@angular/common/http/src/response";
import { environment } from '../../environments/environment'; 
import { UserLocationDetails } from '../models/UserLocationDetails';
import { UserDetails } from '../models/UserDetails';

@Injectable()
export class HomeService {
  

  constructor(private http: HttpClient) {

  }
  // GetUserLocations() {
  //   return Observable.interval(1000)
  //     .flatMap(() => {
  //       return this.http.get<UserLocationDetails[]>(environment.serverPath +'api/qview/userlocs');
  //   });
     
  // } 
//   GetUserDetails() {
//     return this.http.get<any>(environment.serverPath +'api/qview/users');
//  } 
}


