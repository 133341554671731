import { ElementRef } from "@angular/core";
import { environment } from '../environments/environment';
import { AppService } from "./app.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(appService, elementRef) {
        var _this = this;
        this.appService = appService;
        this.elementRef = elementRef;
        this.appService.userName.subscribe(function (user) { return _this.userName = user; });
        console.log("Environment:", environment.serverPath);
    }
    AppComponent.prototype.ngAfterViewInit = function () {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#C0C0C0';
    };
    return AppComponent;
}());
export { AppComponent };
