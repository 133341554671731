import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(router) {
        this.router = router;
        this.headers = new HttpHeaders();
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var token = localStorage.getItem('token');
        if (token) {
            var cloned = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + token).set('Content-Type', 'application/json; charset=utf-8')
            });
            return next.handle(cloned).do(function (event) { }, function (err) {
                if (err instanceof HttpErrorResponse) {
                    switch (Number(err.status)) {
                        case 401:
                            console.log('401 error');
                            //window.location.href= environment.tokenIssuerClient;
                            break;
                    }
                }
            });
        }
        else {
            return next.handle(req);
        }
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
