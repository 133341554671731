var UserDetails = /** @class */ (function () {
    function UserDetails(userName, firstName, lastName, phoneNumber, image, email, team) {
        this.userName = userName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.image = image;
        this.email = email;
        this.team = team;
    }
    return UserDetails;
}());
export { UserDetails };
