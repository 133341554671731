export class UserLocationDetails {

    userName:string;
    locationDTM: Date;
    latitude: number;
    longitude: number;
    constructor(userName:string, locationDTM:Date, latitude:number, longitude:number){
        this.userName = userName;
        this.locationDTM = locationDTM;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}